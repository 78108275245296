<template>
  <div class="bg-gray-100">

    <div
      class="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 flex lg:items-center lg:justify-between sm:flex-col md:flex-row"
    >
      <div class="sm:hidden lg:block md:block md:pr-10" id="header-img">
        <img
          id="phone"
          class="ml-10 md:ml-0"
          src="@/assets/images/iphone_yabetoopay.svg"
          alt=""
        />
      </div>
      <div class="flex flex-col w-6/12 sm:w-full ">
        <span
            class="text-base leading-6 text-indigo-600 font-semibold tracking-wide uppercase mb-10"
        >
      E-Paiement
    </span>
        <h2
          class="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10 mb-20 sm:mb-8"
        >
         Paiement de facture
          <br />
          <span class="text-indigo-600">en ligne.</span>
        </h2>

        <p class="mb-20 text-justify text-xl sm:mb-6">
          Réglez toutes vos factures 24H/24 depuis notre plateforme sans vous
          déplacer et payez par mobile money. <br> <br> Plusieurs professionnel et
          administrations vous permettent de payer vos factures en ligne :
          <span class="text-indigo-600">avocats, artisans, entreprise privées, entreprises publiques…</span>
        </p>

        <div class="mt-8 flex lg:flex-shrink-0 lg:mt-0">
          <div class="inline-flex rounded-md shadow">
            <a
              href="#"
              class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
            >
              Payez une facture
            </a>
          </div>
          <!--<div class="ml-3 inline-flex rounded-md shadow">
            <a
              href="#"
              class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-indigo-600 bg-white hover:text-indigo-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
            >
              Learn more
            </a>
          </div>-->
        </div>
      </div>
     <div class="w-2/12 mt-56 sm:hidden block">
      <img src="@/assets/images/receipt.svg" alt="" />
     </div>
    </div>
  </div>
</template>

<script>
import { gsap, TweenMax, Power2 } from "gsap";
import { CSSPlugin } from "gsap/CSSPlugin";
gsap.registerPlugin(CSSPlugin);

export default {
  name: "E-Paiement",
  data: () => ({
    isMobile: false
  }),
  beforeUnmount() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, { passive: true });
    }
  },
  mounted: function() {
    this.animate();
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  methods: {
    animate() {
      TweenMax.to("#phone", 2, {
        //delay: elementAnimationDelay,
        y: "-=20px",
        yoyo: true,
        repeat: -1,
        ease: Power2.easeInOut //,
        //onCompleteParams:[elementItem],
        //onComplete: elementMoveDown
      });
    },
    onResize() {
      this.isMobile = window.innerWidth < 600;
    }
  }
};
</script>

<style scoped></style>
