<template>
  <!--<div class="font-sans bg-white flex flex-col min-h-screen w-full mb-10">
    <div>
      <div class="bg-gray-200 md:overflow-hidden">
        <div class="px-4 py-16">
          <div class="relative w-full md:max-w-2xl md:mx-auto text-center">
            <h1
              class="font-bold text-gray-700 text-xl sm:text-2xl md:text-5xl leading-tight mb-6"
            >
              Ecosystème de paiement par mobile money.
            </h1>

            <p class="text-gray-600 md:text-xl md:px-18">
              En seulement 15 minutes vous pouvez accepter des paiements par MTN
              et Airtel money sur votre boutique en ligne ou physique. Rejoignez
              l’ensemble des professionnels qui utilisent nos API pour intégrer
              les paiements par mobile money.
            </p>

            <span class="inline-flex rounded-md shadow-sm">
              <a
                href="#"
                class="whitespace-no-wrap inline-flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150 mt-4"
              >
                Ouvrir un compte gratuitement
              </a>
            </span>

            <div
              class="hidden md:block h-40 w-40 rounded-full bg-blue-800 absolute right-0 bottom-0 -mb-64 -mr-48"
            ></div>

            <div
              class="hidden md:block h-5 w-5 rounded-full bg-yellow-500 absolute top-0 right-0 -mr-40 mt-32"
            ></div>
          </div>
        </div>

        <svg
          class="fill-current bg-gray-200 text-white hidden md:block"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
        >
          <path
            fill-opacity="1"
            d="M0,64L120,85.3C240,107,480,149,720,149.3C960,149,1200,107,1320,85.3L1440,64L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
          ></path>
        </svg>
      </div>

      <div
        class="max-w-4xl mx-auto bg-white shadow-lg relative z-20 hidden md:block"
        style="margin-top: -320px; border-radius: 20px;"
      >
        <div
          class="h-20 w-20 rounded-full bg-yellow-500 absolute top-0 left-0 -ml-10 -mt-10"
          style="z-index: -1;"
        ></div>

        <div
          class="h-5 w-5 rounded-full bg-blue-500 absolute top-0 left-0 -ml-32 mt-12"
          style="z-index: -1;"
        ></div>

        <div class="h-10 bg-white rounded-t-lg border-b border-gray-100"></div>
        <div class="flex" style="height: 550px;">
          <div class="w-32 bg-gray-200 p-6 overflow-hidden rounded-bl-lg">
            <div class="text-center mb-10">
              <div
                class="w-10 h-10 rounded-full bg-blue-800 mb-4 mx-auto"
              ></div>
              <div class="h-2 rounded-full bg-blue-800"></div>
            </div>

            <div class="text-center mb-10">
              <div
                class="w-10 h-10 rounded-full bg-gray-300 mb-4 mx-auto"
              ></div>
              <div class="h-2 rounded-full bg-gray-300"></div>
            </div>

            <div class="text-center mb-10">
              <div
                class="w-10 h-10 rounded-full bg-gray-300 mb-4 mx-auto"
              ></div>
              <div class="h-2 rounded-full bg-gray-300"></div>
            </div>

            <div class="text-center">
              <div
                class="w-10 h-10 rounded-full bg-gray-300 mb-4 mx-auto"
              ></div>
              <div class="h-2 rounded-full bg-gray-300"></div>
            </div>
          </div>
          <div class="flex-1 py-6 px-8">
            <div class="flex flex-wrap -mx-4">
              <div class="w-2/3 px-4">
                <div class="flex flex-wrap -mx-4 mb-10">
                  <div class="w-1/4 px-4">
                    <div
                      class="text-center mb-10 bg-white shadow rounded-lg p-6"
                    >
                      <div
                        class="w-10 h-10 rounded-full bg-green-600 mb-4 mx-auto"
                      ></div>
                      <div class="h-2 rounded-full bg-gray-200"></div>
                    </div>
                  </div>
                  <div class="w-1/4 px-4">
                    <div
                      class="text-center mb-10 bg-white shadow rounded-lg p-6"
                    >
                      <div
                        class="w-10 h-10 rounded-full bg-blue-600 mb-4 mx-auto"
                      ></div>
                      <div class="h-2 rounded-full bg-gray-200"></div>
                    </div>
                  </div>
                  <div class="w-1/4 px-4">
                    <div
                      class="text-center mb-10 bg-white shadow rounded-lg p-6"
                    >
                      <div
                        class="w-10 h-10 rounded-full bg-orange-400 mb-4 mx-auto"
                      ></div>
                      <div class="h-2 rounded-full bg-gray-200"></div>
                    </div>
                  </div>
                  <div class="w-1/4 px-4">
                    <div
                      class="text-center mb-10 bg-white shadow rounded-lg p-6"
                    >
                      <div
                        class="w-10 h-10 rounded-full bg-blue-800 mb-4 mx-auto"
                      ></div>
                      <div class="h-2 rounded-full bg-gray-200"></div>
                    </div>
                  </div>
                </div>

                <div class="h-32 percentage mb-10 pt-2">
                  <div class="h-4 bg-gray-200 w-64 mb-4 block"></div>
                  <div class="h-4 bg-gray-200 w-32 mb-4 block"></div>
                  <div class="h-4 bg-gray-200 w-40 mb-4 block"></div>
                  <div class="h-4 bg-gray-200 w-20 mb-4 block"></div>
                </div>

                <div class="w-full flex flex-wrap mb-6">
                  <div class="w-1/2">
                    <div class="flex items-center">
                      <div class="h-8 w-8 rounded-full bg-gray-200 mr-4"></div>
                      <div>
                        <div
                          class="h-2 w-16 bg-gray-200 mb-1 rounded-full"
                        ></div>
                        <div class="h-2 w-10 bg-gray-100 rounded-full"></div>
                      </div>
                    </div>
                  </div>
                  <div class="w-1/2">
                    <div class="flex items-center">
                      <div class="h-8 w-8 rounded-full bg-gray-200 mr-4"></div>
                      <div>
                        <div
                          class="h-2 w-16 bg-gray-200 mb-1 rounded-full"
                        ></div>
                        <div class="h-2 w-10 bg-gray-100 rounded-full"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="w-full flex flex-wrap">
                  <div class="w-1/2">
                    <div class="flex items-center">
                      <div class="h-8 w-8 rounded-full bg-gray-200 mr-4"></div>
                      <div>
                        <div
                          class="h-2 w-16 bg-gray-200 mb-1 rounded-full"
                        ></div>
                        <div class="h-2 w-10 bg-gray-100 rounded-full"></div>
                      </div>
                    </div>
                  </div>
                  <div class="w-1/2">
                    <div class="flex items-center">
                      <div class="h-8 w-8 rounded-full bg-gray-200 mr-4"></div>
                      <div>
                        <div
                          class="h-2 w-16 bg-gray-200 mb-1 rounded-full"
                        ></div>
                        <div class="h-2 w-10 bg-gray-100 rounded-full"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-1/3 px-4">
                <div class="rounded-lg shadow-lg p-6">
                  <div
                    class="block w-12 h-2 rounded-full bg-gray-200 mb-6"
                  ></div>

                  <svg
                    height="150"
                    width="150"
                    viewBox="0 0 20 20"
                    class="mx-auto mb-12"
                  >
                    <circle r="10" cx="10" cy="10" fill="#4299e1" />
                    <circle
                      r="5"
                      cx="10"
                      cy="10"
                      fill="transparent"
                      stroke="#2b6cb0"
                      stroke-width="10"
                      stroke-dasharray="11 31.4"
                      transform="rotate(-90) translate(-20)"
                    />
                  </svg>

                  <div class="flex flex-wrap -mx-2 mb-10">
                    <div class="w-1/3 px-2">
                      <div class="block h-2 rounded-full bg-gray-200"></div>
                    </div>
                    <div class="w-1/3 px-2">
                      <div class="block h-2 rounded-full bg-gray-200"></div>
                    </div>
                    <div class="w-1/3 px-2">
                      <div class="block h-2 rounded-full bg-gray-200"></div>
                    </div>
                  </div>
                </div>

                <div class="flex justify-between my-10">
                  <div>
                    <div class="h-2 w-10 bg-gray-300 rounded-full mb-2"></div>
                    <div class="h-2 w-16 bg-gray-300 rounded-full mb-2"></div>
                    <div class="h-2 w-8 bg-gray-300 rounded-full"></div>
                  </div>
                  <div>
                    <div
                      class="ml-auto h-2 w-5 bg-gray-300 rounded-full mb-2"
                    ></div>
                    <div
                      class="ml-auto h-2 w-6 bg-gray-300 rounded-full mb-2"
                    ></div>
                    <div class="ml-auto h-2 w-8 bg-gray-300 rounded-full"></div>
                  </div>
                </div>

                <div class="text-right flex justify-end">
                  <div class="rounded-lg h-8 w-20 px-4 bg-gray-200 mr-2"></div>
                  <div class="rounded-lg h-8 w-20 px-4 bg-green-400"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="px-4 md:hidden">
        <div
          class="-mt-10 max-w-4xl mx-auto bg-white shadow-lg relative z-20"
          style="border-radius: 20px;"
        >
          <div
            class="h-10 bg-white rounded-t-lg border-b border-gray-100"
          ></div>
          <div class="flex" style="height: 340px;">
            <div
              class="w-16 bg-gray-200 px-2 py-6 overflow-hidden rounded-bl-lg"
            >
              <div class="text-center mb-6">
                <div
                  class="w-4 h-4 rounded-full bg-blue-800 mb-2 mx-auto"
                ></div>
                <div class="h-2 w-8 mx-auto rounded-full bg-blue-800"></div>
              </div>
              <div class="text-center mb-6">
                <div
                  class="w-4 h-4 rounded-full bg-gray-300 mb-2 mx-auto"
                ></div>
                <div class="h-2 w-8 mx-auto rounded-full bg-gray-300"></div>
              </div>
              <div class="text-center mb-6">
                <div
                  class="w-4 h-4 rounded-full bg-gray-300 mb-2 mx-auto"
                ></div>
                <div class="h-2 w-8 mx-auto rounded-full bg-gray-300"></div>
              </div>
              <div class="text-center">
                <div
                  class="w-4 h-4 rounded-full bg-gray-300 mb-2 mx-auto"
                ></div>
                <div class="h-2 w-8 mx-auto rounded-full bg-gray-300"></div>
              </div>
            </div>
            <div class="flex-1 py-6 px-4">
              <div class="flex flex-wrap -mx-2">
                <div class="w-1/3 px-2">
                  <div
                    class="text-center mb-6 bg-white shadow rounded-lg px-2 py-3"
                  >
                    <div
                      class="w-4 h-4 rounded-full bg-green-600 mb-2 mx-auto"
                    ></div>
                    <div class="h-2 w-8 mx-auto rounded-full bg-gray-200"></div>
                  </div>
                </div>
                <div class="w-1/3 px-2">
                  <div
                    class="text-center mb-6 bg-white shadow rounded-lg px-2 py-3"
                  >
                    <div
                      class="w-4 h-4 rounded-full bg-blue-600 mb-2 mx-auto"
                    ></div>
                    <div class="h-2 w-8 mx-auto rounded-full bg-gray-200"></div>
                  </div>
                </div>
                <div class="w-1/3 px-2">
                  <div
                    class="text-center mb-6 bg-white shadow rounded-lg px-2 py-3"
                  >
                    <div
                      class="w-4 h-4 rounded-full bg-orange-600 mb-2 mx-auto"
                    ></div>
                    <div class="h-2 w-8 mx-auto rounded-full bg-gray-200"></div>
                  </div>
                </div>
              </div>

              <div class="flex flex-wrap -mx-2 mb-6">
                <div class="w-1/2 px-2">
                  <div class="shadow h-24 p-2 rounded-lg">
                    <div class="h-20 percentage pt-2">
                      <div class="h-2 bg-gray-200 w-24 mb-2 block"></div>
                      <div class="h-2 bg-gray-200 w-12 mb-2 block"></div>
                      <div class="h-2 bg-gray-200 w-20 mb-2 block"></div>
                      <div class="h-2 bg-gray-200 w-8 mb-2 block"></div>
                    </div>
                  </div>
                </div>
                <div class="w-1/2 px-2">
                  <div class="rounded-lg shadow px-2 py-2">
                    <div
                      class="block w-8 h-2 rounded-full bg-gray-200 mb-2"
                    ></div>

                    <div class="mb-2">
                      <svg
                        height="50"
                        width="50"
                        viewBox="0 0 20 20"
                        class="mx-auto"
                      >
                        <circle r="10" cx="10" cy="10" fill="#ddd" />
                        <circle
                          r="5"
                          cx="10"
                          cy="10"
                          fill="transparent"
                          stroke="#eee"
                          stroke-width="10"
                          stroke-dasharray="11 31.4"
                          transform="rotate(-90) translate(-20)"
                        />
                      </svg>
                    </div>

                    <div class="flex flex-wrap -mx-2">
                      <div class="w-1/3 px-2">
                        <div class="block h-2 rounded-full bg-gray-200"></div>
                      </div>
                      <div class="w-1/3 px-2">
                        <div class="block h-2 rounded-full bg-gray-200"></div>
                      </div>
                      <div class="w-1/3 px-2">
                        <div class="block h-2 rounded-full bg-gray-200"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="w-full flex flex-wrap mb-2">
                <div class="w-1/2">
                  <div class="flex items-center">
                    <div class="h-4 w-4 rounded-full bg-gray-200 mr-4"></div>
                    <div>
                      <div class="h-2 w-10 bg-gray-200 mb-1 rounded-full"></div>
                      <div class="h-2 w-6 bg-gray-100 rounded-full"></div>
                    </div>
                  </div>
                </div>
                <div class="w-1/2">
                  <div class="flex items-center">
                    <div class="h-4 w-4 rounded-full bg-gray-200 mr-4"></div>
                    <div>
                      <div class="h-2 w-10 bg-gray-200 mb-1 rounded-full"></div>
                      <div class="h-2 w-6 bg-gray-100 rounded-full"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="w-full flex flex-wrap mb-6">
                <div class="w-1/2">
                  <div class="flex items-center">
                    <div class="h-4 w-4 rounded-full bg-gray-200 mr-4"></div>
                    <div>
                      <div class="h-2 w-10 bg-gray-200 mb-1 rounded-full"></div>
                      <div class="h-2 w-6 bg-gray-100 rounded-full"></div>
                    </div>
                  </div>
                </div>
                <div class="w-1/2">
                  <div class="flex items-center">
                    <div class="h-4 w-4 rounded-full bg-gray-200 mr-4"></div>
                    <div>
                      <div class="h-2 w-10 bg-gray-200 mb-1 rounded-full"></div>
                      <div class="h-2 w-6 bg-gray-100 rounded-full"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="text-right flex justify-end">
                <div class="rounded-lg h-6 w-16 px-4 bg-gray-200 mr-2"></div>
                <div class="rounded-lg h-6 w-16 px-4 bg-green-400"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>-->

  <!-- component -->
  <!-- This is an example component -->
  <div>
    <div class="bg-indigo-900 md:overflow-hidden">
      <div class="px-4 py-20 md:py-4">
        <div class="md:max-w-6xl md:mx-auto">
          <div class="md:flex md:flex-wrap">
            <div class="md:w-1/2 text-center md:text-left md:pt-16">
              <h1
                  class="font-bold text-white text-2xl md:text-5xl leading-tight mb-4"
              >
                Ecosystème de paiement par mobile money.
              </h1>

              <p class="text-indigo-200 md:text-xl ">
                En seulement 15 minutes vous pouvez accepter des paiements par MTN et Airtel money sur votre boutique en ligne ou physique. Rejoignez l’ensemble des professionnels qui utilisent nos API pour intégrer les paiements par mobile money.
              </p>

              <a
                  href="https://app.yabetoo.com/auth/register"
                  target="_blank"
                  class="mt-6 mb-12 md:mb-0 md:mt-10 inline-block py-3 px-8 text-white bg-red-500 hover:bg-red-600 rounded-lg shadow"
              >Démarrer maintenant</a
              >
            </div>
            <div class="md:w-1/2 relative">
              <div class="hidden md:block">
                <div
                    class="-ml-24 -mb-40 absolute left-0 bottom-0 w-40 bg-white rounded-lg shadow-lg px-6 py-8"
                    style="transform: rotate(-8deg);"
                >
                  <div
                      class="bg-indigo-800 mx-auto rounded-lg px-2 pb-2 relative mb-8"
                  >
                    <div class="mb-1">
                      <span
                          class="w-1 h-1 bg-indigo-100 rounded-full inline-block"
                          style="margin-right: 1px;"
                      ></span
                      ><span
                        class="w-1 h-1 bg-indigo-100 rounded-full inline-block"
                        style="margin-right: 1px;"
                    ></span
                    ><span
                        class="w-1 h-1 bg-indigo-100 rounded-full inline-block"
                    ></span>
                    </div>
                    <div class="h-1 w-12 bg-indigo-100 rounded mb-1"></div>
                    <div class="h-1 w-10 bg-indigo-100 rounded mb-2"></div>

                    <div class="flex">
                      <div class="w-6 h-3 rounded bg-indigo-100 mr-1"></div>
                      <div class="w-6 h-3 rounded bg-indigo-100"></div>
                    </div>

                    <div
                        class="-mr-2 -mb-4 absolute bottom-0 right-0 h-16 w-10 rounded-lg bg-green-700 border-2 border-white"
                    ></div>
                    <div
                        class="w-2 h-2 rounded-full bg-green-800 mx-auto absolute bottom-0 right-0 mr-2 -mb-2 z-10 border-2 border-white"
                    ></div>
                  </div>

                  <div class="text-gray-800 text-center">
                    Online <br />Services
                  </div>
                </div>

                <div
                    class="ml-24 mb-16 absolute left-0 bottom-0 w-40 bg-white rounded-lg shadow-lg px-6 py-8"
                    style="transform: rotate(-8deg); z-index: 2;"
                >
                  <div
                      class="bg-indigo-800 mx-auto rounded-lg relative mb-8 py-2 w-20 border-2 border-white"
                  >
                    <div
                        class="h-8 bg-green-700 w-8 rounded absolute left-0 top-0 -mt-3 ml-4"
                        style="transform: rotate(-45deg); z-index: -1;"
                    ></div>
                    <div
                        class="h-8 bg-green-800 w-8 rounded absolute left-0 top-0 -mt-3 ml-8"
                        style="transform: rotate(-12deg); z-index: -2;"
                    ></div>

                    <div
                        class="flex items-center justify-center h-6 bg-indigo-800 w-6 rounded-l-lg ml-auto border-4 border-white -mr-1"
                    >
                      <div
                          class="h-2 w-2 rounded-full bg-indigo-800 border-2 border-white"
                      ></div>
                    </div>

                    <div
                        class="w-8 h-8 bg-green-700 border-4 border-white rounded-full -ml-3 -mb-5"
                    ></div>
                  </div>

                  <div class="text-gray-800 text-center">
                    Banking Services
                  </div>
                </div>

                <div
                    class="ml-32 absolute left-0 bottom-0 w-48 bg-white rounded-lg shadow-lg px-10 py-8"
                    style="transform: rotate(-8deg); z-index: 2; margin-bottom: -220px;"
                >
                  <div
                      class="bg-indigo-800 mx-auto rounded-lg pt-4 mb-16 relative"
                  >
                    <div class="h-4 bg-white"></div>

                    <div class="text-right my-2 pb-1">
                      <div
                          class="inline-flex w-3 h-3 rounded-full bg-white -mr-2"
                      ></div>
                      <div
                          class="inline-flex w-3 h-3 rounded-full bg-indigo-800 border-2 border-white mr-2"
                      ></div>
                    </div>

                    <div
                        class="-ml-4 -mb-6 absolute left-0 bottom-0 w-16 bg-green-700 mx-auto rounded-lg pb-2 pt-3"
                    >
                      <div class="h-2 bg-white mb-2"></div>
                      <div class="h-2 bg-white w-6 ml-auto rounded mr-2"></div>
                    </div>
                  </div>

                  <div class="text-gray-800 text-center">
                    Payment for <br />Internet
                  </div>
                </div>

                <div
                    class="mt-10 w-full absolute right-0 top-0 flex rounded-lg bg-white overflow-hidden shadow-lg"
                    style="transform: rotate(-8deg); margin-right: -250px; z-index: 1;"
                >
                  <div class="w-32 bg-gray-200" style="height: 560px;"></div>
                  <div class="flex-1 p-6">
                    <h2 class="text-lg text-gray-700 font-bold mb-3">
                      Popular Payments
                    </h2>
                    <div class="flex mb-5">
                      <div class="w-16 rounded-full bg-gray-100 py-2 px-4 mr-2">
                        <div class="p-1 rounded-full bg-gray-300"></div>
                      </div>
                      <div class="w-16 rounded-full bg-gray-100 py-2 px-4 mr-2">
                        <div class="p-1 rounded-full bg-gray-300"></div>
                      </div>
                      <div class="w-16 rounded-full bg-gray-100 py-2 px-4 mr-2">
                        <div class="p-1 rounded-full bg-gray-300"></div>
                      </div>
                      <div class="w-16 rounded-full bg-gray-100 py-2 px-4">
                        <div class="p-1 rounded-full bg-gray-300"></div>
                      </div>
                    </div>

                    <div class="flex flex-wrap -mx-4 mb-5">
                      <div class="w-1/3 px-4">
                        <div class="h-40 rounded-lg bg-white shadow-lg p-6">
                          <div
                              class="w-16 h-16 rounded-full bg-gray-200 mb-6"
                          ></div>
                          <div
                              class="h-2 w-16 bg-gray-200 mb-2 rounded-full"
                          ></div>
                          <div class="h-2 w-10 bg-gray-200 rounded-full"></div>
                        </div>
                      </div>
                      <div class="w-1/3 px-4">
                        <div class="h-40 rounded-lg bg-white shadow-lg p-6">
                          <div
                              class="w-16 h-16 rounded-full bg-gray-200 mb-6"
                          ></div>
                          <div
                              class="h-2 w-16 bg-gray-200 mb-2 rounded-full"
                          ></div>
                          <div class="h-2 w-10 bg-gray-200 rounded-full"></div>
                        </div>
                      </div>
                      <div class="w-1/3 px-4">
                        <div class="h-40 rounded-lg bg-white shadow-lg p-6">
                          <div
                              class="w-16 h-16 rounded-full bg-gray-200 mb-6"
                          ></div>
                          <div
                              class="h-2 w-16 bg-gray-200 mb-2 rounded-full"
                          ></div>
                          <div class="h-2 w-10 bg-gray-200 rounded-full"></div>
                        </div>
                      </div>
                    </div>

                    <h2 class="text-lg text-gray-700 font-bold mb-3">
                      Popular Payments
                    </h2>

                    <div
                        class="w-full flex flex-wrap justify-between items-center border-b-2 border-gray-100 py-3"
                    >
                      <div class="w-1/3">
                        <div class="flex">
                          <div class="h-8 w-8 rounded bg-gray-200 mr-4"></div>
                          <div>
                            <div
                                class="h-2 w-16 bg-gray-200 mb-1 rounded-full"
                            ></div>
                            <div
                                class="h-2 w-10 bg-gray-100 rounded-full"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div class="w-1/3">
                        <div
                            class="w-16 rounded-full bg-green-100 py-2 px-4 mx-auto"
                        >
                          <div class="p-1 rounded-full bg-green-200"></div>
                        </div>
                      </div>
                      <div class="w-1/3">
                        <div
                            class="h-2 w-10 bg-gray-100 rounded-full mx-auto"
                        ></div>
                      </div>
                    </div>

                    <div
                        class="flex flex-wrap justify-between items-center border-b-2 border-gray-100 py-3"
                    >
                      <div class="w-1/3">
                        <div class="flex">
                          <div class="h-8 w-8 rounded bg-gray-200 mr-4"></div>
                          <div>
                            <div
                                class="h-2 w-16 bg-gray-200 mb-1 rounded-full"
                            ></div>
                            <div
                                class="h-2 w-10 bg-gray-100 rounded-full"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div class="w-1/3">
                        <div
                            class="w-16 rounded-full bg-orange-100 py-2 px-4 mx-auto"
                        >
                          <div class="p-1 rounded-full bg-orange-200"></div>
                        </div>
                      </div>
                      <div class="w-1/3">
                        <div
                            class="h-2 w-16 bg-gray-100 rounded-full mx-auto"
                        ></div>
                      </div>
                    </div>

                    <div
                        class="flex flex-wrap justify-between items-center border-b-2 border-gray-100 py-3"
                    >
                      <div class="w-1/3">
                        <div class="flex">
                          <div class="h-8 w-8 rounded bg-gray-200 mr-4"></div>
                          <div>
                            <div
                                class="h-2 w-16 bg-gray-200 mb-1 rounded-full"
                            ></div>
                            <div
                                class="h-2 w-10 bg-gray-100 rounded-full"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div class="w-1/3">
                        <div
                            class="w-16 rounded-full bg-blue-100 py-2 px-4 mx-auto"
                        >
                          <div class="p-1 rounded-full bg-blue-200"></div>
                        </div>
                      </div>
                      <div class="w-1/3">
                        <div
                            class="h-2 w-8 bg-gray-100 rounded-full mx-auto"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                    class="w-full absolute left-0 bottom-0 ml-1"
                    style="transform: rotate(-8deg); z-index: 1; margin-bottom: -360px;"
                >
                  <div class="grid--gray h-48 w-48"></div>
                </div>
              </div>

              <div
                  class="md:hidden w-full absolute right-0 top-0 flex rounded-lg bg-white overflow-hidden shadow"
              >
                <div
                    class="h-4 bg-gray-200 absolute top-0 left-0 right-0 rounded-t-lg flex items-center"
                >
                  <span
                      class="h-2 w-2 rounded-full bg-red-500 inline-block mr-1 ml-2"
                  ></span>
                  <span
                      class="h-2 w-2 rounded-full bg-orange-400 inline-block mr-1"
                  ></span>
                  <span
                      class="h-2 w-2 rounded-full bg-green-500 inline-block mr-1"
                  ></span>
                </div>
                <div class="w-32 bg-gray-100 px-2 py-8" style="height: 340px;">
                  <div class="h-2 w-16 bg-gray-300 rounded-full mb-4"></div>
                  <div class="flex items-center mb-4">
                    <div
                        class="h-5 w-5 rounded-full bg-gray-300 mr-3 flex-shrink-0"
                    ></div>
                    <div>
                      <div class="h-2 w-10 bg-gray-300 rounded-full"></div>
                    </div>
                  </div>

                  <div class="h-2 w-16 bg-gray-200 rounded-full mb-2"></div>
                  <div class="h-2 w-10 bg-gray-200 rounded-full mb-2"></div>
                  <div class="h-2 w-20 bg-gray-200 rounded-full mb-2"></div>
                  <div class="h-2 w-6 bg-gray-200 rounded-full mb-2"></div>
                  <div class="h-2 w-16 bg-gray-200 rounded-full mb-2"></div>
                  <div class="h-2 w-10 bg-gray-200 rounded-full mb-2"></div>
                  <div class="h-2 w-20 bg-gray-200 rounded-full mb-2"></div>
                  <div class="h-2 w-6 bg-gray-200 rounded-full mb-2"></div>
                </div>
                <div class="flex-1 px-4 py-8">
                  <h2 class="text-xs text-gray-700 font-bold mb-1">
                    Popular Payments
                  </h2>
                  <div class="flex mb-5">
                    <div class="p-2 w-12 rounded-full bg-gray-100 mr-2"></div>
                    <div class="p-2 w-12 rounded-full bg-gray-100 mr-2"></div>
                    <div class="p-2 w-12 rounded-full bg-gray-100 mr-2"></div>
                    <div class="p-2 w-12 rounded-full bg-gray-100 mr-2"></div>
                  </div>

                  <div class="flex flex-wrap -mx-2 mb-5">
                    <div class="w-1/3 px-2">
                      <div class="p-3 rounded-lg bg-white shadow">
                        <div
                            class="w-6 h-6 rounded-full bg-gray-200 mb-2"
                        ></div>
                        <div
                            class="h-2 w-10 bg-gray-200 mb-1 rounded-full"
                        ></div>
                        <div class="h-2 w-6 bg-gray-200 rounded-full"></div>
                      </div>
                    </div>
                    <div class="w-1/3 px-2">
                      <div class="p-3 rounded-lg bg-white shadow">
                        <div
                            class="w-6 h-6 rounded-full bg-gray-200 mb-2"
                        ></div>
                        <div
                            class="h-2 w-10 bg-gray-200 mb-1 rounded-full"
                        ></div>
                        <div class="h-2 w-6 bg-gray-200 rounded-full"></div>
                      </div>
                    </div>
                    <div class="w-1/3 px-2">
                      <div class="p-3 rounded-lg bg-white shadow">
                        <div
                            class="w-6 h-6 rounded-full bg-gray-200 mb-2"
                        ></div>
                        <div
                            class="h-2 w-10 bg-gray-200 mb-1 rounded-full"
                        ></div>
                        <div class="h-2 w-6 bg-gray-200 rounded-full"></div>
                      </div>
                    </div>
                  </div>

                  <h2 class="text-xs text-gray-700 font-bold mb-1">
                    Popular Payments
                  </h2>

                  <div
                      class="w-full flex flex-wrap justify-between items-center border-b-2 border-gray-100 py-3"
                  >
                    <div class="w-1/3">
                      <div class="flex">
                        <div
                            class="h-5 w-5 rounded-full bg-gray-200 mr-3 flex-shrink-0"
                        ></div>
                        <div>
                          <div
                              class="h-2 w-16 bg-gray-200 mb-1 rounded-full"
                          ></div>
                          <div class="h-2 w-10 bg-gray-100 rounded-full"></div>
                        </div>
                      </div>
                    </div>
                    <div class="w-1/3">
                      <div
                          class="w-16 rounded-full bg-green-100 py-2 px-4 mx-auto"
                      >
                        <div class="p-1 rounded-full bg-green-200"></div>
                      </div>
                    </div>
                    <div class="w-1/3">
                      <div
                          class="h-2 w-10 bg-gray-100 rounded-full mx-auto"
                      ></div>
                    </div>
                  </div>

                  <div class="flex flex-wrap justify-between items-center py-3">
                    <div class="w-1/3">
                      <div class="flex">
                        <div
                            class="h-5 w-5 rounded-full bg-gray-200 mr-3 flex-shrink-0"
                        ></div>
                        <div>
                          <div
                              class="h-2 w-16 bg-gray-200 mb-1 rounded-full"
                          ></div>
                          <div class="h-2 w-10 bg-gray-100 rounded-full"></div>
                        </div>
                      </div>
                    </div>
                    <div class="w-1/3">
                      <div
                          class="w-16 rounded-full bg-orange-100 py-2 px-4 mx-auto"
                      >
                        <div class="p-1 rounded-full bg-orange-200"></div>
                      </div>
                    </div>
                    <div class="w-1/3">
                      <div
                          class="h-2 w-16 bg-gray-100 rounded-full mx-auto"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                  class="mr-3 md:hidden absolute right-0 bottom-0 w-40 bg-white rounded-lg shadow-lg px-10 py-6"
                  style="z-index: 2; margin-bottom: -380px;"
              >
                <div
                    class="bg-indigo-800 mx-auto rounded-lg w-20 pt-3 mb-12 relative"
                >
                  <div class="h-3 bg-white"></div>

                  <div class="text-right my-2">
                    <div
                        class="inline-flex w-3 h-3 rounded-full bg-white -mr-2"
                    ></div>
                    <div
                        class="inline-flex w-3 h-3 rounded-full bg-indigo-800 border-2 border-white mr-2"
                    ></div>
                  </div>

                  <div
                      class="-ml-4 -mb-6 absolute left-0 bottom-0 w-16 bg-green-700 mx-auto rounded-lg pb-2 pt-3"
                  >
                    <div class="h-2 bg-white mb-2"></div>
                    <div class="h-2 bg-white w-6 ml-auto rounded mr-2"></div>
                  </div>
                </div>

                <div class="text-gray-800 text-center text-sm">
                  Payment for <br />Internet
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <svg
          class="fill-current text-white hidden md:block"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
      >
        <path fill-opacity="1" d="M0,224L1440,32L1440,320L0,320Z"></path>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header"
};
</script>

<style scoped></style>
