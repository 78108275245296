<template>
  <!--
  Tailwind UI components require Tailwind CSS v1.8 and the @tailwindcss/ui plugin.
  Read the documentation to get started: https://tailwindui.com/documentation
-->
  <div class="bg-stripe-100 text-white">
    <div
      class="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between"
    >
      <div class="flex flex-col sm:w-full">
        <span class="mb-10 text-lg text-stripe-200"
          >Conçue pour les développeurs</span
        >
        <h2
          class="text-3xl leading-9 font-extrabold tracking-tight  sm:text-4xl sm:leading-10 mb-10"
        >
          Des API performantes et
          <br />
          <span class="text-indigo-600">simples d'utilisation.</span>
        </h2>

        <p class="w-auto mb-10 text-justify text-stripe-300 pr-10 sm:w-full sm:pr-0">
          Développeurs intégrez gratuitement les paiements mobiles money Airtel
          et MTN dans vos applications et sites web grâce à nos API conçus pour
          cet effet.
        </p>

        <div class="mt-8 flex lg:flex-shrink-0 lg:mt-0">
          <div class="inline-flex rounded-md shadow">
            <a
              href="https://docs.yabetoopay.com"
              target="_blank"
              class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
            >
              Lire la documentation
            </a>
          </div>
        </div>
        <div class="flex justify-between w-10/12 mt-10 sm:w-full">
          <div>
            <h3 class="mb-8">
              Des outils adaptés à toutes <br />
              les infrastructures
            </h3>
            <p class="text-justify text-stripe-300">
              Nous proposons de nombreuses bibliothèques client et serveur, dont
              React, PHP, .NET et iOS.
            </p>
          </div>
          <div class="pl-10">
            <h3 class="mb-8">Intégrations préconfigurées</h3>
            <p class="text-justify text-stripe-300">
              Utilisez des intégrations conçues pour des systèmes tels que
              Shopify, WooCommerce, PrestaShop et bien d'autres.
            </p>
          </div>
        </div>
      </div>
      <div class="mt-8 flex lg:flex-shrink-0 lg:mt-0">
        <div
          class="coding inverse-toggle px-5 pt-4 shadow-lg text-gray-100 text-sm font-mono subpixel-antialiased
              bg-gray-800  pb-6 pt-4 rounded-lg leading-normal overflow-hidden"
        >
          <div class="top mb-2 flex">
            <div class="h-3 w-3 bg-red-500 rounded-full"></div>
            <div class="ml-2 h-3 w-3 bg-orange-300 rounded-full"></div>
            <div class="ml-2 h-3 w-3 bg-green-500 rounded-full"></div>
          </div>
          <div class="mt-4">
            <prism-editor
              class="my-editor bg-gray-800"
              v-model="code"
              :highlight="highlighter"
              line-numbers
            ></prism-editor>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Prism Editor
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css"; // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-tomorrow.css"; // import syntax highlighting styles

export default {
  name: "Developer",
  components: {
    PrismEditor
  },
  data: () => ({
    code: `import axios from 'axios';

await axios.post(url, data : {
    amount: '200',
    phone_number: '2420687898745'
});

`
  }),
  methods: {
    highlighter(code) {
      return highlight(code, languages.js); // languages.<insert language> to return html with markup
    }
  }
};
</script>

<style scoped>
/* required class */
.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  color: #ccc;

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 8px;
  //background: #32325d;
  padding: 25px 30px;
  width: 525px;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}
</style>
